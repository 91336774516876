import { graphql, useStaticQuery } from 'gatsby'

const useUltimasNoticias = () => {
    const resultado = useStaticQuery(graphql`
    {
      allStrapiNoticias(
        sort: {order: DESC, fields: strapiId}
        filter: {categoria_noticia: {id: {eq: 1}}}
      ) {
        nodes {
          id
          nombre
          contenido
          created_at
          strapiId
          imagenes {
            url
          }
        }
      }
    }
  `)
  return resultado.allStrapiNoticias.nodes.slice(0,8)
}

export default useUltimasNoticias;