import React from 'react';
import '../../styles/components/index/_youtube.scss';
export const YoutubeIframe = ({youtube}) => {
    const {embed}=youtube;

    return (
        <div className="video-responsive">
            <iframe width="850" 
                height="480" 
                src={`https://www.youtube.com/embed/${embed}`} 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture" 
                allowfullscreen />
        </div>
      
    )
}
