import React from 'react'
import { Col, Row} from 'react-bootstrap'
import { YoutubeIframe } from './youtubeIframe'
import {v4 as uuid} from 'uuid';


export const GaleriaYoutube = () => {
  const youtube=[
        {   
            id:uuid(),
            title:'YouTube video player',
            embed:'9E8boERBJ7I'
        },
        {   
            id:uuid(),
            title:'YouTube video player',
            embed:'ajFBE7nWmts'
        },
        {
            id:uuid(),
            title:'YouTube video player',
            embed:'5zrFO5IdhBs'
        },
        {
            id:uuid(),
            title:'YouTube video player',
            embed:'6zqX4ZI85aw'
        }]
return (
    <>
      <h2 xs={1} lg={12}>Galeria Youtube</h2>
      <Row xs={2} md={8} className="justify-content-md-center container__card">
        { youtube && youtube.map( e => (
          <Col xs={12} md={ 6 } lg={ 4 } key={ e.id } className="mt-5 container__card__noticia animate__animated  animate__fadeIn">
            <YoutubeIframe
              youtube={e}
            />
          </Col>
        )).slice(0,4)}
      </Row>
    </>
  )
}
