import React from 'react'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import { Noticias } from '../components/index/noticias'
import { Enlaces } from '../components/index/enlaces'
import { Principal } from '../components/index/principal'
import { Nosotros } from '../components/index/nosotros'
import { GaleriaYoutube } from '../components/index/galeriaYoutube'
import { Col, Row } from 'react-bootstrap'
import "../styles/styles.scss"
import 'animate.css';


const IndexPage = () => {
  return (
    <Layout >
      <Principal />
      <Container  >
        <Row > 
          <Col xs={12} md={12} className="mt-5">
            <Nosotros />
          </Col>
          <Col xs={12} md={12} lg={12} className="mt-5">
            <Enlaces />  
          </Col> 
          <Col xs={12} md={12} lg={12} className="mt-5 mb-5">
            <Noticias  />
          </Col>
          <Col xs={12} md={12} lg={12} className="mt-5 mb-5">
            <GaleriaYoutube  />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage