import React from 'react'
import urlSlug from 'url-slug'
import { Link } from 'gatsby'
import { Card, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

export const CardNoticia = ({noticia}) => {
    
    const { nombre, contenido, id, imagenes } = noticia
    const imagen = imagenes.slice(0 , 1);

    return (
        <Card bg="dark"   >
            {
            imagen?  
            imagen.map( e => (
                        <Card.Img
                            variant="top"
                            src={`http://167.71.109.215:1337${e.url}`} 
                            style={{ maxHeight:130 }}
                            key={e.url}
                        />
                ))
            :
            ( 
            <SkeletonTheme color="#343a40" highlightColor="#6c757d">
            <p>
                <Skeleton delay={1}  height={100} width={`${100}%`}  />
            </p>
          </SkeletonTheme>
          )
            }
            <Row>
                <Col>
                    <Card.Body >
                        {/* style={{ width: '19rem', height:'10rem' }} */}
                        {
                            nombre?
                            (
                                <Card.Title>{ nombre } </Card.Title>
                            )
                            :
                            (
                                <SkeletonTheme color="#343a40" highlightColor="#6c757d">
                                    <p>
                                        <Skeleton delay={1}  height={20} width={`${100}%`}  />
                                    </p>
                                </SkeletonTheme>

                            )
                        }
                        <Card.Text style={{ fontSize:14 }}>
                        {
                            contenido?
                            (
                                `${contenido.substr(0,40)} ...`
                            )
                            :
                            (
                                <SkeletonTheme color="#343a40" highlightColor="#6c757d">
                                    <p>
                                        <Skeleton delay={1}  height={20} width={`${100}%`}  />
                                    </p>
                                </SkeletonTheme>
                            )
                        }
                        </Card.Text>
                        <Button variant="outline-info" size="sm"><Link to={`noticias/${urlSlug(id)}`} style={{color:'inherit', textDecoration:'inherit'}}>Ver más... </Link></Button>
                    </Card.Body>
                </Col>
                
            </Row>
        </Card>
    )
}
