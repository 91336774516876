import React, { useRef, useState } from 'react'
import { Card, Col, Overlay, Tooltip } from 'react-bootstrap'

export const Enlace = ({enlace}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

    return (
        <Col key={enlace.id} xs={12} md={6}  lg={2}  ref={target} onMouseOver={() => setShow(!show)}>
            <a href={enlace.ref} target="_blank" rel="noreferrer">
              <Card 
                bg="dark" 
                className="d-flex justify-content-center align-items-center w-100 mt-3" 
              >
                <Card.Body >
                  <img 
                    src={enlace.icon} 
                    alt={enlace.name} 
                    height="35" 
                    style={{maxWidth:`${100}%`}} 
                  /> 
                  <Overlay target={target.current} show={show} placement="bottom">
                    {(props) => (
                      <Tooltip id={enlace.id} {...props}>
                        {enlace.name}
                      </Tooltip>
                    )}
                  </Overlay>
                </Card.Body>
              </Card>
            </a>
          </Col>
    )
}
