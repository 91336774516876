import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row  } from 'react-bootstrap'

export const Nosotros = () => {
    return (
      <>
        <Row className="text-center">
          <Col md={12} className="mb-3">
            <StaticImage 
              src="../../images/logo-dark.png" 
              alt="colegio" 
              height={ 130 }
              />
          </Col>
          <Col md={ 12} className="">
            <p className="fs-4">Ofrecer una educación de alta calidad pedagógica, así como la formación integral de los estudiantes, y tiene como fundamento el fortalecimiento de sus actitudes, valores, conocimientos y habilidades, de modo que puedan continuar sus estudios de nivel secundario e integrarse a la sociedad como miembros activos que contribuyen a ella.</p>
          </Col>
        </Row>
      </>
    )
}
