import React from 'react'
import { Col, Row} from 'react-bootstrap'
import useUltumasNoticias from '../../hooks/useUltimasNoticias'
import { CardNoticia } from './cardNoticia'


export const Noticias = () => {
  const ultimasNoticias = useUltumasNoticias()
  return (
    <>
      <h2 xs={1} lg={12}>Ultimas Noticias</h2>
      <Row xs={2} md={8} className="justify-content-md-center container__card">
        { ultimasNoticias && ultimasNoticias.map( e => (
          <Col xs={12} md={ 4 } lg={ 3 } key={ e.id } className="mt-5 container__card__noticia animate__animated  animate__fadeIn">
            <CardNoticia
              noticia={e}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}
