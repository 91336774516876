import React from 'react'
import * as LinkWeb from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {Carousel, Row, Col, Button, Container} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'

export const Principal = () => {
    return (
      <Carousel
        fade 
        interval={10000}
        activeIndex={0}
      >
        <Carousel.Item >
          <StaticImage 
            src="../../images/slice/01.jpg" 
            alt="" 
            placeholder="blurred"
            className="d-block w-100"
          /> 
          <Carousel.Caption>
            <Container>
              <Row >
                <Col xs={12} md={7} lg={7} >
                  <StaticImage 
                    src="../../images/logo-dark.png" 
                    alt="logo" 
                    height={130}
                  /> 
                  <h3>Colegio cielos del valle</h3>
                  <h4>"Construyendo aprendizajes de calidad"</h4>
                </Col>
                <Col xs={1} md={5}>
                  <StaticImage 
                    src="../../images/png/nina-aprendiendo.png" 
                    alt="" 
                    placeholder="blurred"
                    layout="fullWidth"
                  /> 
                </Col>
              </Row>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item >
          <StaticImage 
            src="../../images/slice/02.jpg" 
            alt="" 
            placeholder="blurred"
            className="d-block w-100"
          /> 
          <Carousel.Caption>
            <Row className="align-items-center">
              <Col xs={1} md={7}>
                <StaticImage 
                  src="../../images/png/conferencia2.png" 
                  alt="" 
                  placeholder="blurred"
                  layout="fullWidth"
                /> 
              </Col>
              <Col xs={12} md={5} >
                <h2 className="fs-1 text-dark">Clases Online</h2>
                <p className="fs-4 text-dark">Puedes ver tu horario de clases</p>
                <Button variant="dark" size="lg" onClick={ () => LinkWeb.navigate("/comunidad/horario-clases")} >
                  <span><FontAwesomeIcon icon={faFileAlt} /> Revisar</span>
                </Button>
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage 
            src="../../images/slice/03.jpg" 
            alt="" 
            placeholder="blurred"
            className="d-block w-100"
          /> 
          <Carousel.Caption>
            <Row className="align-items-center">
              <Col xs={12} md={6} >
                <h2 className="fs-1 text-dark">Retorno Seguro</h2>
                <h4 className="fs-4 text-dark">Revisa nuestras normas para un retorno seguro a clases</h4>
                <Button variant="dark" size="lg" onClick={()=> {
                  LinkWeb.navigate("/retorno-seguro")
                }} ><span><FontAwesomeIcon icon={faFileAlt} /> Revisar</span></Button>
              </Col>
              <Col xs={1} md={6} >
                <StaticImage 
                    src="../../images/png/ninos-mascarilla.png" 
                    alt="" 
                    placeholder="blurred"
                    layout="fullWidth"
                  /> 
              </Col>
            </Row>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )
}
